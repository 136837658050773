import { createEvent, createStore, sample } from "effector";
import { TaskList } from "../types/backgroundTasks.types";
import {
  getTaskListFx,
  addTaskIdFx,
  removeTaskIdFx,
  getTaskIdListFx,
  retryTaskFx,
} from "./backgroundTasks.datalayer";

// стор тасок
export const $taskList = createStore<TaskList[]>([]);

// Эвент вызывающий перезапрос тасок используя айдишинки из текщего стора
export const TaskListShouldUpdate = createEvent();

// эффекты меняющие лс и эвент который требует обновить данные из лс вызывают эффект доставания данных из ЛС
sample({
  clock: [addTaskIdFx.finally, removeTaskIdFx.finally],
  target: [getTaskIdListFx],
});

// ретрай вызывает перезапрос тасок с сервака.
sample({ clock: retryTaskFx.finally, target: TaskListShouldUpdate });

// записываем результаты хттп запроса за тасками в стор
sample({
  clock: getTaskListFx.doneData,
  target: $taskList,
  fn: (data) => data.items.reverse(),
});
