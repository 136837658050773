import { $accessToken } from "dhEntities/user/store";
import { message } from "antd";
export const CORE_API = process.env.NEXT_PUBLIC_CORE_API + "/api";

export type BaseListRequest = {
  sortField?: string;
  sortDirection?: string;
  pageSize?: number;
  startIndex?: number;
};

export type ListResponse<T> = {
  startIndex: number;
  totalItems: number;
  currentItemCount: number;
  items: T[];
};

export type ApiErrorMessage = {
  reason: string;
  message: string;
  location: string;
};

export type ApiError = {
  code: number;
  message: string;
  errors: ApiErrorMessage[];
};

export type FetchParams = {
  route: string;
  method?: string;
  headers?: any;
  body?: any;
  query?: { [key: string]: any };
  versionApi?: number;
};

export const getUrl = (url: string, query = {}, versionApi = 1): string => {
  return withQuery(`${CORE_API}/v${versionApi}${url}`, query);
};

export const withQuery = (url: string, params = {}): string => {
  if (!params || Object.entries(params).length === 0) return url;
  const query = Object.entries(params)
    .filter(([key, value]) => key !== undefined && value !== undefined)
    .map(([key, value]) => {
      return Array.isArray(value)
        ? value.map((arrValue) => `${key}=${arrValue}`).join("&")
        : `${key}=${value}`;
    })
    .join("&");

  return `${url}?${query}`;
};

export const fetchCoreApi = async <ResponseResult>(
  params: FetchParams
): Promise<ResponseResult> => {
  const r = await fetch(getUrl(params.route, params.query, params.versionApi), {
    method: params.method ?? "GET",
    headers: params.headers ?? {
      "Content-Type": "application/json",
      Authorization: `Bearer ${$accessToken.getState()}`,
    },
    body: params.body ? JSON.stringify(params.body) : undefined,
  });

  if (r.status === 204) {
    return {} as ResponseResult;
  }

  if ([503, 504].includes(r.status)) {
    throw {
      code: r.status,
      message: r.statusText,
      errors: [
        {
          reason: "Сервер не отвечает",
          message: "Сервер не отвечает",
          location: params.route,
        },
      ],
    };
  }
  let json;
  try {
    // блядский бэкэнд может прислать пустой ответ, поэтому лайфхачим тут
    json = await r.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });
  } catch (error) {
    const text = `Статус ${r.status} при запросе ${params.route}`;
    message.error(text);
    return {} as ResponseResult;
  }

  if (!r.ok) {
    throw json.error;
  }

  return json.data;
};
