export const theme = {
  colors: { red: "#ff4d4f", gray: "#a5a9af" },
  token: {
    colorPrimary: "#13c2c2",
  },
  components: {
    Typography: {
      fontSizeHeading1: 28,
      fontSizeHeading2: 22,
      fontWeightStrong: 500,
    },
    Menu: {
      //base
      colorItemText: "#fff",

      // selected
      colorItemTextSelected: "#fff",
      colorItemBgSelected: "#323232",

      // hover
      colorItemBgHover: "#323232",
      colorItemTextHover: "#fff",
    },
  },
};
