import {
  IntegrationObject,
  IntegrationObjectCreateResponse,
  IntegrationObjectsListRequest,
  IntegrationObjectsListResponse,
} from "./types";
import { fetchCoreApi } from "utils/fetch";

export const getObjects = async (
  params: IntegrationObjectsListRequest
): Promise<IntegrationObjectsListResponse> => {
  return fetchCoreApi<IntegrationObjectsListResponse>({
    route: "/integration-object",
    query: params,
  });
};

export const getIntegrationObjects = async (): Promise<IntegrationObject[]> => {
  const data = await fetchCoreApi<IntegrationObjectsListResponse>({
    route: "/integration-object/?pageSize=50&startIndex=0",
  });
  return data?.items ?? [];
};

export const getIntegrationObject = async (
  id: string
): Promise<IntegrationObject> => {
  const data = await fetchCoreApi<IntegrationObject>({
    route: `/integration-object/${id}`,
  });
  return data;
};

export const createIntegrationObject = async (
  params: Omit<IntegrationObject, "id" | "fields">
): Promise<IntegrationObjectCreateResponse> => {
  return fetchCoreApi<IntegrationObjectCreateResponse>({
    method: "POST",
    route: "/integration-object/",
    body: params,
  });
};

export const activateIntegrationObject = async (
  integrationObjectVersionId: string
): Promise<any> => {
  return fetchCoreApi<IntegrationObject>({
    method: "POST",
    route: `/integration-object-release/activate`,
    body: {
      versionId: integrationObjectVersionId,
    },
    versionApi: 2,
  });
};

export const updateIntegrationObject = async (
  params: Omit<
    IntegrationObject,
    "userCreated" | "userModified" | "createdAt" | "updatedAt"
  >
): Promise<IntegrationObject> => {
  return await fetchCoreApi<any>({
    method: "PUT",
    route: `/integration-object/${params.id}/`,
    body: params,
  });
};

export const patchIntegrationObject = async (
  params: Pick<IntegrationObject, "id" | "alias" | "name" | "description">
): Promise<IntegrationObject> => {
  return await fetchCoreApi<any>({
    method: "PATCH",
    route: `/integration-object/${params.id}/`,
    body: params,
  });
};

export const deteleIntegrationObject = async (id: string): Promise<any> => {
  return await fetchCoreApi<any>({
    method: "DELETE",
    route: `/integration-object/${id}/`,
  });
};
