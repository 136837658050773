import { fetchCoreApi, getUrl } from "utils/fetch";
import { TaskResponse } from "../types/backgroundTasks.types";

export const getTask = (ids: string[]): Promise<TaskResponse> =>
  fetchCoreApi({
    route: "/jobs/",
    query: { ids },
    versionApi: 1,
    method: "GET",
  });

export const reloadTask = (id: string): Promise<void> =>
  fetchCoreApi({
    route: "/jobs/" + id + "/restart",
    method: "POST",
    versionApi: 1,
  });
