import Link from "next/link";
import {
  BuildOutlined,
  ApiOutlined,
  WalletOutlined,
  ShopOutlined,
  UserOutlined,
  EnvironmentOutlined,
  CloudOutlined,
  DownloadOutlined,
  DashOutlined,
} from "@ant-design/icons";
import style from "./SidebarMenu.module.css";

export const items = [
  {
    label: "Информация обо мне",
    key: "",
    icon: <UserOutlined />,
  },
  {
    label: "Интеграционные объекты",
    key: "integration-objects",
    icon: <BuildOutlined />,
  },
  {
    label: "Бизнес-домены",
    key: "business-domains",
    icon: <WalletOutlined />,
  },
  {
    label: "Продукты",
    key: "products",
    icon: <ShopOutlined />,
  },
  {
    label: "Интеграции",
    key: "integrations",
    icon: <ApiOutlined />,
  },
  {
    label: "Расположения систем",
    key: "system-locations",
    icon: <EnvironmentOutlined />,
  },
  {
    label: "Кластеры Kafka",
    key: "kafka-clusters",
    icon: <CloudOutlined />,
  },
  {
    label: "Потребители",
    key: "consumer-group",
    icon: <DownloadOutlined />,
  },
  {
    label: "Топики",
    key: "topics",
    icon: <DashOutlined />,
  },
];

export const menuItems = items.map(({ label, key, icon }) => ({
  label: (
    <Link prefetch={false} href={`/${key}`} className={style.menulink}>
      {label}
    </Link>
  ),
  key,
  icon,
  className: style.menuItem,
}));

// const x = [
//   {
//     label: (
//       <Link href="/" className={style.menulink}>
//         Информация обо мне
//       </Link>
//     ),
//     key: "/",
//     icon: <UserOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/integration-objects" className={style.menulink}>
//         Интеграционные объекты
//       </Link>
//     ),
//     key: "integration-objects",
//     icon: <BuildOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/business-domains" className={style.menulink}>
//         Бизнес-домены
//       </Link>
//     ),
//     key: "business-domains",
//     icon: <WalletOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/products" className={style.menulink}>
//         Продукты
//       </Link>
//     ),
//     key: "products",
//     icon: <ShopOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/integrations" className={style.menulink}>
//         Интеграции
//       </Link>
//     ),
//     key: "integrations",
//     icon: <ApiOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/system-locations" className={style.menulink}>
//         Расположения систем
//       </Link>
//     ),
//     key: "system-locations",
//     icon: <EnvironmentOutlined />,
//     className: style.menuItem,
//   },
//   {
//     label: (
//       <Link href="/kafka-clusters" className={style.menulink}>
//         Кластеры Kafka
//       </Link>
//     ),
//     key: "kafka-clusters",
//     icon: <CloudOutlined />,
//     className: style.menuItem,
//   },
// ];
