import { createEffect, sample } from "effector";
import Router from "next/router";
import {
  TaskState,
  ChangeOffsetState,
  TaskList,
  TaskTypes,
  TaskStateTypes,
} from "../../types/backgroundTasks.types";
import {
  $taskStateList,
  $taskCompletedList,
} from "../backgroundTasks.success.model";
import { setOffsetFx } from "dhEntities/consumerGroup/model/events";
import { addTaskIdFx } from "../backgroundTasks.datalayer";
import { consumerGroupReloadData } from "dhEntities/consumerGroup/model/consumerGroup.model";

// подписка на событие изменения офсета
sample({
  clock: setOffsetFx.doneData,
  source: setOffsetFx,
  fn: (props: ChangeOffsetState, data: string) => {
    return {
      id: data,
      type: TaskTypes.CHANGEOFFSET,
      state: { ...props },
    };
  },
  target: addTaskIdFx,
});

// эффект успешной таски изменения оффсета
export const taskChangeOffsetCompletedFx = createEffect({
  sid: "taskChangeOffsetCompletedFx",
  handler: (tasks: (TaskState<ChangeOffsetState> | undefined)[]) => {
    tasks.forEach((task) => {
      Router.push(
        `/kafka-clusters/${task?.state.kafkaClusterId}/consumer-group/${task?.state.externalSystemId}`
      );
      consumerGroupReloadData();
    });
  },
});

// обработчик успешной таски изменения оффсета
sample({
  source: $taskStateList,
  clock: $taskCompletedList,
  fn: (taskIdlist, list: TaskList[]) => {
    const tasks = list.filter(
      (item) => item.entityType === TaskTypes.CHANGEOFFSET
    );
    return tasks.map((task): TaskState<ChangeOffsetState> | undefined =>
      taskIdlist.find(
        (
          info: TaskState<TaskStateTypes>
        ): info is TaskState<ChangeOffsetState> => info.id === task.name
      )
    );
  },
  target: taskChangeOffsetCompletedFx,
});
