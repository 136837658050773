import { LOCAL_STORAGE_ID } from "../const/backgroundTasks.const";
import { TaskState, TaskStateTypes } from "../types/backgroundTasks.types";

// апи для работы с локалстораджем

// сеттер таск айди в лс
export const setTaskIdInLS = (task: TaskState<TaskStateTypes>) => {
  const taskListFromLS = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_ID) || "[]"
  );

  if (
    !taskListFromLS.find((t: TaskState<TaskStateTypes>) => t.id === task.id) &&
    typeof task !== undefined
  ) {
    localStorage.setItem(
      LOCAL_STORAGE_ID,
      JSON.stringify([...taskListFromLS, task])
    );
  }
};

// геттер айди таскок из лс
export const getTaskIdList = (): TaskState<TaskStateTypes>[] => {
  if (typeof window !== "undefined") {
    return JSON.parse(localStorage?.getItem(LOCAL_STORAGE_ID) || "[]");
  }
  return [];
};

// ремув тасок
export const removeTaskId = (id: string): void => {
  if (typeof window !== "undefined") {
    const data = JSON.parse(
      localStorage?.getItem(LOCAL_STORAGE_ID) || "[]"
    ).filter((item: TaskState<TaskStateTypes>) => item.id !== id);

    localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify(data));
  }
};
