import { ChangeOffsetsRequestBody } from "dhEntities/consumerGroup/types/types";
import { Scenario } from "dhEntities/scenario/model/types";
export interface TaskResponse {
  items: TaskList[];
}

export interface TaskList {
  name: string;
  createTime: string;
  endTime: string;
  batchStatus: TaskBatchStatus;
  executionStatus: TaskExecutionStatus;
  jobHistories: Task[];
  entityId: string;
  entityType: TaskTypes;
  description: string;
}

export interface Task {
  batchStatus: TaskBatchStatus;
  executionStatus: TaskExecutionStatus;
  exitMessage: string;
  startTime: string;
  createTime: string;
  endTime: string;
  lastUpdated: string;
  stepHistories: TaskStep[];
}

export interface TaskStep {
  batchStatus: string;
  executionStatus: string;
  stepName: string;
  startTime: string;
  endTime: string;
  lastUpdated: string;
  description: string;
  exitMessage: string;
}

export type UiTaskItem = {
  id: string;
  title: string;
  color: string;
  status: string;
  message?: string;
};

export enum TaskBatchStatus {
  COMPLETED = "COMPLETED",
  STARTING = "STARTING",
  STARTED = "STARTED",
  STOPPING = "STOPPING",
  STOPPED = "STOPPED",
  FAILED = "FAILED",
  ABANDONED = "ABANDONED",
  UNKNOWN = "UNKNOWN",
}

export enum TaskExecutionStatus {
  EXECUTING = "EXECUTING",
  COMPLETED = "COMPLETED",
  NOOP = "NOOP",
  FAILED = "FAILED",
  STOPPED = "STOPPED",
  UNKNOWN = "UNKNOWN",
}

export enum TaskTypes {
  SCENARIO = "SCENARIO",
  INTEGRATION_OBJECT_VERSION = "INTEGRATION_OBJECT_VERSION",
  CHANGEOFFSET = "CHANGEOFFSET",
  CLEARTOPIC = "CLEARTOPIC",
}

export type TaskState<TaskStateTypes> = {
  id: string;
  type: TaskTypes;
  state: TaskStateTypes;
};

export type IntegrationObjectTaskState = {
  integrationObjectid?: string;
  releaseId?: string;
  versionId: string;
};

export type ChangeOffsetState = ChangeOffsetsRequestBody & {
  kafkaClusterId: string;
  externalSystemId: string;
  name: string;
};

export type ClearTopicState = { id: string };

export type ScenarioTaskState = Omit<Scenario, "id">;

export type TaskStateTypes =
  | IntegrationObjectTaskState
  | ScenarioTaskState
  | ChangeOffsetState
  | ClearTopicState;
