import { createStore, sample } from "effector";
import { setAccessToken } from "./events";
import { Project } from "dhEntities/project/model/types";
import { getMyEmployeeInfoFx } from "dhEntities/emploee/model/events";
import { InvolvedTeamMember } from "dhEntities/emploee/model/types";

export const $accessToken = createStore<string>("");
export const $myTeamsInvolvements = createStore<InvolvedTeamMember[]>([]);
export const $myProjects = createStore<Project[]>([]);

$accessToken.on(setAccessToken, (_, token) => token);

sample({
  clock: $accessToken,
  target: getMyEmployeeInfoFx,
});

sample({
  clock: getMyEmployeeInfoFx.doneData,
  fn: (data) =>
    data.involvedTeamMembers.sort((a, b) =>
      a.team.isActive < b.team.isActive ? 1 : -1
    ),
  target: $myTeamsInvolvements,
});
