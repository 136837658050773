import type { FC } from "react";
import { useRouter } from "next/router";
import { Menu } from "antd";
import style from "./SidebarMenu.module.css";
import { menuItems } from "./SidebarMenu.const";

type SidebarMenu = {
  onClick?: () => void;
};

export const SidebarMenu: FC<SidebarMenu> = ({ onClick }) => {
  const { pathname } = useRouter();
  const key = pathname.split("/")[1];
  const currentKey = key === "" ? "/" : key;
  return (
    <Menu
      mode="vertical"
      items={menuItems}
      selectedKeys={[currentKey]}
      className={style.menu}
      onClick={onClick}
    />
  );
};
