import { fetchCoreApi } from "utils/fetch";
import {
  ConsumerGroup,
  ConsumerGroupListResponse,
  ConsumerGroupRequestParams,
  ClusterConsumerGroupListRequestParams,
  ChangeOffsetsRequestBody,
} from "../types/types";

const baseRoute = "/live/consumer-group";

export const getConsumerGroup = async (
  params: ConsumerGroupRequestParams
): Promise<ConsumerGroup> => {
  return await fetchCoreApi<ConsumerGroup>({
    route: baseRoute,
    query: params,
  });
};

export const getClusterConsumerGroups = async (
  params: ClusterConsumerGroupListRequestParams
): Promise<ConsumerGroupListResponse> => {
  return await fetchCoreApi<ConsumerGroupListResponse>({
    route: `${baseRoute}/cluster/${params.kafkaClusterId}`,
  });
};

export const getTopicConsumerGroups = async (
  id: string
): Promise<ConsumerGroupListResponse> => {
  return await fetchCoreApi<ConsumerGroupListResponse>({
    route: `${baseRoute}/topic/${id}`,
  });
};

export const setOffset = async (
  params: ChangeOffsetsRequestBody
): Promise<string> => {
  return await fetchCoreApi<string>({
    route: `${baseRoute}/change-offset`,
    method: "POST",
    body: params,
  });
};
