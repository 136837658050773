import { FC } from "react";
import cn from "classnames";
import Link from "next/link";
import styles from "./Logo.module.css";

type LogoProps = {
  size?: "small" | "medium";
  theme?: "light" | "dark";
  className?: string;
};

export const Logo: FC<LogoProps> = ({
  size = "medium",
  theme = "dark",
  className = "",
}) => {
  const isSmall = size === "small";
  const isMedium = size === "medium";
  const isDark = theme === "dark";
  const isLight = theme === "light";
  return (
    <Link
      className={cn({
        [styles.logo]: true,
        [styles.logo__small]: isSmall,
        [styles.logo__medium]: isMedium,
        [className]: true,
      })}
      href="/"
    >
      <span
        className={cn({
          [styles.data]: true,
          [styles.data__hidden]: isSmall,
          [styles.data__dark]: isDark,
          [styles.data__light]: isLight,
        })}
      >
        Data
      </span>
      <span className={styles.hub}>{isMedium ? "hub" : "dh"}</span>
    </Link>
  );
};
