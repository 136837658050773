import { useSession, signIn } from "next-auth/react";
import styles from "./Auth.module.css";
import { FC, ReactNode, useEffect } from "react";
import { setAccessToken } from "dhEntities/user/events";
type AuthProps = {
  children: ReactNode;
};

export const Auth: FC<AuthProps> = ({ children }) => {
  const { data, status } = useSession();

  useEffect(() => {
    if (data?.error === "RefreshAccessTokenError") {
      signIn("keycloak");
    }
  }, [data]);
  if (status === "loading") {
    return <div className={styles.overlay}>Загрузка...</div>;
  }
  if (status === "authenticated") {
    setAccessToken(data.accessToken);
    return <>{children}</>;
  }
  signIn("keycloak");
  return <></>;
};
