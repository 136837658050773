import { clearTopicApi } from "dhEntities/kafkaTopic/model/api";
import { createEffect, createEvent, sample } from "effector";
import { ApiError } from "utils/fetch";

export const clearTopicEvent = createEvent<string>();

export const clearTopicFx = createEffect<string, string, ApiError>({
  handler: async (id) => {
    return clearTopicApi(id);
  },
  sid: "clearTopicFx",
});

sample({ clock: clearTopicEvent, target: clearTopicFx });
