import { createEffect } from "effector";

import { getMyEmployeeInfo } from "dhEntities/emploee/model/api";
import { Employee } from "dhEntities/emploee/model/types";
import { ApiError } from "utils/fetch";

export const getMyEmployeeInfoFx = createEffect<void, Employee, ApiError>(
  async () => {
    return await getMyEmployeeInfo();
  }
);
