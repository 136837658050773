import { createEffect } from "effector";
import {
  IntegrationObjectCreateResponse,
  IntegrationObject,
  IntegrationObjectsListRequest,
  IntegrationObjectsListResponse,
  IntegartionObjectIds,
} from "./types";
import {
  getObjects,
  getIntegrationObject,
  createIntegrationObject,
  updateIntegrationObject,
  patchIntegrationObject,
  deteleIntegrationObject,
  activateIntegrationObject,
} from "./api";
import { ApiError } from "utils/fetch";

export const getObjectsFx = createEffect<
  IntegrationObjectsListRequest,
  IntegrationObjectsListResponse,
  ApiError
>({
  handler: async (params: IntegrationObjectsListRequest) => {
    return getObjects(params);
  },
  sid: "getObjectsListFx",
});

export const getIntegrationObjectFx = createEffect<
  string,
  IntegrationObject,
  ApiError
>({
  handler: async (id) => {
    return await getIntegrationObject(id);
  },
  sid: "getIntegrationObjectFx",
});

export const createIntegrationObjectFx = createEffect<
  Omit<IntegrationObject, "id" | "fields">,
  IntegrationObjectCreateResponse,
  ApiError
>({
  handler: async (newIntegrationObject) => {
    return await createIntegrationObject(newIntegrationObject);
  },
  sid: "createIntegrationObjectFx",
});

export const updateIntegrationObjectFx = createEffect<
  IntegrationObject,
  IntegrationObject,
  ApiError
>({
  handler: async (newIntegrationObject) => {
    return await updateIntegrationObject(newIntegrationObject);
  },
  sid: "updateIntegrationObjectFx",
});

export const patchIntegrationObjectFx = createEffect<
  Pick<IntegrationObject, "id" | "alias" | "name" | "description">,
  IntegrationObject,
  ApiError
>({
  handler: async (newIntegrationObject) => {
    return await patchIntegrationObject(newIntegrationObject);
  },
  sid: "patchIntegrationObjectFx",
});

export const deleteIntegrationObjectFx = createEffect<
  string,
  IntegrationObject,
  ApiError
>({
  handler: async (id) => {
    return await deteleIntegrationObject(id);
  },
  sid: "deleteIntegrationObjectFx",
});

export const activateIntegrationObjectFx = createEffect<
  IntegartionObjectIds,
  string,
  ApiError
>({
  handler: async (ids) => {
    return await activateIntegrationObject(ids.versionId as string);
  },
  sid: "activateIntegrationObjectFx",
});
