import { createEffect, sample } from "effector";
import Router from "next/router";
import { TaskList, TaskTypes } from "../../types/backgroundTasks.types";
import { $taskCompletedList } from "../backgroundTasks.success.model";
import { createScenarioFx } from "dhEntities/scenario/model/events";
import { Scenario } from "dhEntities/scenario/model/types";
import { addTaskIdFx } from "../backgroundTasks.datalayer";

// подписка на событие создания сценария
sample({
  clock: createScenarioFx.doneData,
  source: createScenarioFx,
  fn: (props: Omit<Scenario, "id">, data: string) => {
    return {
      id: data,
      type: TaskTypes.SCENARIO,
      state: { ...props },
    };
  },
  target: addTaskIdFx,
});

// эффект успешной таски создания сценария
export const taskScenarioCompletedFx = createEffect({
  sid: "taskScenarioCompletedFx",
  handler: (tasks: (TaskList | undefined)[]) => {
    tasks.forEach((task) => {
      Router.push("/integrations/" + task?.entityId);
    });
  },
});

// обработчик успешной таски создания сценария
sample({
  clock: $taskCompletedList,
  fn: (list) => {
    const newlist = list.filter(
      (item) => item.entityType === TaskTypes.SCENARIO
    );
    return newlist;
  },
  target: taskScenarioCompletedFx,
});
