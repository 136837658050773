import { createStore, createEvent, sample, combine } from "effector";
import { ConsumerGroup, ConsumerGroupRequestParams } from "../types/types";
import { getConsumerGroupFx, getKafkaClusterLiveFx } from "./events";
import { KafkaClusterLive } from "dhEntities/kafkaCluster/model/types";

export const $kafkaClusterId = createStore<string | null>(null);

export const $kafkaClusterLive = createStore<KafkaClusterLive | null>(null);

export const setKafkaClusterIdEvent = createEvent<string>();

export const $externalSystemId = createStore<string | null>(null);

export const $consumerGroup = createStore<ConsumerGroup | null>(null);

export const externalSystemIdEvent = createEvent<string>();
export const consumerGroupReloadData = createEvent();

sample({ clock: setKafkaClusterIdEvent, target: $kafkaClusterId });
sample({ clock: externalSystemIdEvent, target: $externalSystemId });

sample({ source: getConsumerGroupFx.doneData, target: $consumerGroup });

sample({
  clock: combine($kafkaClusterId, $externalSystemId),
  filter: ([clusterId, systemId]) =>
    typeof clusterId === "string" && typeof systemId === "string",
  fn: (ids) =>
    ({
      externalSystemId: ids[1],
      kafkaClusterId: ids[0],
    } as ConsumerGroupRequestParams),
  target: getConsumerGroupFx,
});

sample({
  source: combine($kafkaClusterId, $externalSystemId),
  clock: consumerGroupReloadData,
  filter: ([topicId, systemId]) =>
    typeof topicId === "string" && typeof systemId === "string",
  fn: (ids) =>
    ({
      externalSystemId: ids[1],
      kafkaClusterId: ids[0],
    } as ConsumerGroupRequestParams),
  target: getConsumerGroupFx,
});

sample({
  clock: $kafkaClusterId,
  target: getKafkaClusterLiveFx,
  filter: (id) => typeof id === "string",
  fn: (id) => id as string,
});

sample({ source: getKafkaClusterLiveFx.doneData, target: $kafkaClusterLive });

sample({
  source: $kafkaClusterId,
  clock: consumerGroupReloadData,
  fn: (id) => id as string,
  target: getKafkaClusterLiveFx,
});
