import { fetchCoreApi, getUrl } from "utils/fetch";
import EventSource from "eventsource";
import { $accessToken } from "dhEntities/user/store";
import {
  KafkaTopicLive,
  KafkaTopic,
  KafkaTopicsListRequestParams,
  KafkaTopicsLiveListRequestParams,
  KafkaTopicListResponse,
  KafkaTopicLiveListResponse,
} from "./types";

const baseRoute = "/kafka-topic";

export const clearTopicApi = async (id: string): Promise<string> => {
  return await fetchCoreApi<string>({
    route: `/${baseRoute}/${id}/clear`,
    method: "POST",
  });
};

export const getKafkaTopics = async (
  params: KafkaTopicsListRequestParams
): Promise<KafkaTopicListResponse> => {
  return await fetchCoreApi<KafkaTopicListResponse>({
    route: baseRoute,
    query: params,
  });
};

export const getKafkaTopicsLive = async (
  params: KafkaTopicsLiveListRequestParams
): Promise<KafkaTopicLiveListResponse> => {
  return await fetchCoreApi<KafkaTopicLiveListResponse>({
    route: `${baseRoute}/live`,
    query: params,
  });
};

export const getKafkaTopic = async (id: string): Promise<KafkaTopic> => {
  return await fetchCoreApi<KafkaTopic>({
    route: `${baseRoute}/${id}`,
  });
};

export const getKafkaTopicLive = async (
  id: string
): Promise<KafkaTopicLive> => {
  return await fetchCoreApi<KafkaTopicLive>({
    route: `${baseRoute}/${id}/live`,
  });
};

export type TopicMessagesSSERequest = {
  topicId: string;
  direction: "FORWARD" | "LIVE";
  type: "BEGINNING" | "LATEST" | "OFFSET" | "TIMESTAMP";
  filterQueries?: string[];
  limit?: number;
  from?: string;
  showHiddenFields?: boolean;
  showRemoved?: boolean;
  partitionsWithOffsets?: string[];
};

export const getMessagesSSEConnection = ({
  topicId,
  direction,
  type,
  from,
  limit = 20,
  filterQueries,
  showHiddenFields,
  showRemoved,
  partitionsWithOffsets,
}: TopicMessagesSSERequest): EventSource => {
  const url = getUrl(`/kafka-message`, {
    topicId,
    limit,
    direction,
    type,
    from,
    filterQueries,
    showHiddenFields,
    showRemoved,
    partitionsWithOffsets,
  });
  return new EventSource(url, {
    headers: {
      Authorization: `Bearer ${$accessToken.getState()}`,
    },
  });
};
