import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteOutlined,
  LoadingOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Button, Col, Collapse, Empty, Row, Space, Typography } from "antd";

import style from "./BackgroundTaskList.module.css";
import { useStore } from "effector-react";
import { FC, memo, useEffect } from "react";
import {
  $taskList,
  $taskStateList,
  TaskExecutionStatus,
  TaskListShouldUpdate,
  TaskState,
  TaskStateTypes,
  TaskTypes,
  removeTaskIdFx,
} from "../model";
import { DrawerSize } from "./BackgroundTaskListDrawer";
import dayjs from "dayjs";
import dynamic from "next/dynamic";
import { ClearTopicStateComponent } from "./BackgroundTaskStates/ClearTopicState";

const BackgroundTaskListPipeline = dynamic(
  () => import("./BackgroundTaskListPipeline")
);
const ChangeOffsetStateComponent = dynamic(
  () => import("./BackgroundTaskStates/ChangeOffsetState")
);
const ScenarioObjectState = dynamic(
  () => import("./BackgroundTaskStates/ScenarioObjectState")
);
const IntegrationObjectState = dynamic(
  () => import("./BackgroundTaskStates/IntegrationObjectState")
);

const { Panel } = Collapse;
const { Paragraph } = Typography;

export const BackgroundTaskList: FC<{
  drawerSizeHandler: (size: DrawerSize) => void;
}> = ({ drawerSizeHandler }) => {
  const taskList = useStore($taskList);
  const taskStateList = useStore($taskStateList);

  const collapseChange = (state: string[] | string) => {
    if (typeof state === "string") {
      drawerSizeHandler("large");
    } else {
      if (state.length > 0) {
        drawerSizeHandler("large");
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        taskList.some(
          (task) =>
            task.executionStatus === TaskExecutionStatus.NOOP ||
            task.executionStatus === TaskExecutionStatus.UNKNOWN
        )
      ) {
        TaskListShouldUpdate();
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [taskList]);

  const getTaskStateFactory = (taskState: TaskState<TaskStateTypes>) => {
    switch (taskState.type) {
      case TaskTypes.INTEGRATION_OBJECT_VERSION:
        return <IntegrationObjectState taskState={taskState} />;
      case TaskTypes.SCENARIO:
        return <ScenarioObjectState taskState={taskState} />;
      case TaskTypes.CHANGEOFFSET:
        return <ChangeOffsetStateComponent taskState={taskState} />;
      case TaskTypes.CLEARTOPIC:
        return <ClearTopicStateComponent taskState={taskState} />;
      default:
        return <></>;
    }
  };

  const getTaskStatusIcon = (staus: TaskExecutionStatus) => {
    switch (staus) {
      case TaskExecutionStatus.NOOP:
      case TaskExecutionStatus.UNKNOWN:
        return <LoadingOutlined color="#13c2c2" />;
      case TaskExecutionStatus.COMPLETED:
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case TaskExecutionStatus.FAILED:
        return <CloseCircleTwoTone twoToneColor="red" />;
      default:
        return <QuestionCircleTwoTone />;
    }
  };

  if (!taskList || taskList.length === 0) {
    return <Empty />;
  }

  return (
    <Collapse>
      {taskList.map((task, i) => {
        const taskState = taskStateList.find(
          (ts: TaskState<TaskStateTypes>) => ts.id === task.name
        );

        return (
          <Panel
            key={task.name}
            header={
              <div className={style.header}>
                <Space>
                  <Paragraph>
                    {getTaskStatusIcon(task.executionStatus)}
                  </Paragraph>
                  <Paragraph>{task.description}</Paragraph>
                  <Paragraph copyable>{task.name}</Paragraph>
                </Space>{" "}
                <Space>
                  <Paragraph>
                    Создана:{" "}
                    {dayjs(task.jobHistories.slice(-1)[0].lastUpdated).format(
                      "HH:mm:ss  DD.MM.YY"
                    )}
                  </Paragraph>
                  <Paragraph>
                    Изменена:{" "}
                    {dayjs(task.jobHistories[0].lastUpdated).format(
                      "HH:mm:ss  DD.MM.YY"
                    )}
                  </Paragraph>
                </Space>
              </div>
            }
            extra={
              <>
                <Button
                  icon={<DeleteOutlined />}
                  disabled={
                    (task.executionStatus === TaskExecutionStatus.NOOP ||
                      task.executionStatus === TaskExecutionStatus.UNKNOWN) &&
                    true
                  }
                  onClick={() => removeTaskIdFx(task.name)}
                ></Button>
              </>
            }
          >
            <Row gutter={16}>
              <Col span={12}>{taskState && getTaskStateFactory(taskState)}</Col>
              <Col span={12}>
                <BackgroundTaskListPipeline
                  task={task}
                  collapseChange={collapseChange}
                />
              </Col>
            </Row>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default memo(BackgroundTaskList);
