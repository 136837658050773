import { FC, useState } from "react";
import { Avatar, Drawer, Typography, Button, Divider } from "antd";
import { useSession, signOut } from "next-auth/react";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import style from "./UserAvatar.module.css";
import { $myTeamsInvolvements } from "dhEntities/user/store";
import { getMyEmployeeInfoFx } from "dhEntities/emploee/model/events";

import { useStore } from "effector-react";

const { Paragraph, Title, Text } = Typography;

export const UserAvatar: FC = ({}) => {
  const [open, setOpen] = useState(false);
  const { data } = useSession();
  const name = data?.user?.name;
  const email = data?.user?.email;

  const myTeamsInvolvements = useStore($myTeamsInvolvements);
  const involvesPending = useStore<boolean>(getMyEmployeeInfoFx.pending);

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    signOut();
  };
  return (
    <>
      <Avatar onClick={showDrawer} className={style.avatar}>
        <UserOutlined />
      </Avatar>

      <Drawer
        title={name}
        placement="right"
        onClose={onCloseDrawer}
        open={open}
      >
        <Title level={4}>Личная информация</Title>
        <Paragraph>
          Email: <Text strong>{email}</Text>
        </Paragraph>
        <Divider />
        <Title level={4}>Текущие команды</Title>
        {involvesPending ? (
          <div>Загрузка</div>
        ) : (
          <ul className={style.teamsList}>
            {myTeamsInvolvements
              .filter((t) => t.isActive)
              .map((t) => (
                <li key={t.id}>
                  {t.team.name} ({t.roles.join(", ")})
                </li>
              ))}
          </ul>
        )}
        <Divider />
        <Button
          ghost
          block
          type="primary"
          onClick={handleLogout}
          icon={<LogoutOutlined />}
        >
          Выйти из DataHub
        </Button>
      </Drawer>
    </>
  );
};
