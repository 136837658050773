import { createEffect } from "effector";
import {
  Scenario,
  ScenariosListRequest,
  ScenarioInfoRequest,
  ScenarioInfoListResponse,
  ScenariosListResponse,
  HowToResponse,
  ScenarioInfo,
  IncomingScenarioCreateRequest,
} from "./types";
import {
  getScenarios,
  getScenariosInfo,
  createScenario,
  getHowTo,
  getScenario,
  getScenarioInfo,
  deleteScenario,
} from "./api";

import { ApiError } from "utils/fetch";

// TODO: попробовать либу для женитьбы эффектора и некст жс, чтобы не ебаться с sidами
export const getScenariosFx = createEffect<
  ScenariosListRequest,
  ScenariosListResponse,
  ApiError
>({
  handler: async (params: ScenariosListRequest) => {
    return await getScenarios(params);
  },
  sid: "getScenariosFx",
});

export const getScenarioFx = createEffect<string, Scenario, ApiError>({
  handler: async (id: string) => {
    return await getScenario(id);
  },
  sid: "getScenarioFx",
});

export const getScenariosInfoFx = createEffect<
  ScenarioInfoRequest,
  ScenarioInfoListResponse,
  ApiError
>({
  handler: async (params: ScenarioInfoRequest) => {
    return await getScenariosInfo(params);
  },
  sid: "getScenariosInfoFx",
});

export const getScenarioInfoFx = createEffect<string, ScenarioInfo, ApiError>({
  handler: async (id: string) => {
    return await getScenarioInfo(id);
  },
  sid: "getScenarioInfoFx",
});

export const createScenarioFx = createEffect<
  IncomingScenarioCreateRequest | Omit<Scenario, "id">,
  string,
  ApiError
>({
  handler: async (
    scenario: IncomingScenarioCreateRequest | Omit<Scenario, "id">
  ) => {
    return await createScenario({ ...scenario, instanceCount: 1 });
  },
  sid: "createScenarioFx",
});

export const getHowToFx = createEffect<string, HowToResponse, ApiError>({
  handler: async (scenarioId: string) => {
    return await getHowTo(scenarioId);
  },
  sid: "getHowToFx",
});

export const deleteScenarioFx = createEffect<string, string, ApiError>({
  handler: async (id: string) => {
    return await deleteScenario(id);
  },
  sid: "deleteScenarioFx",
});
