import { Card, Space, Typography } from "antd";
import { FC, memo } from "react";
import Link from "next/link";
import {
  TaskState,
  TaskStateTypes,
  ClearTopicState,
} from "widgets/BackgroundTaskList/model";

const { Paragraph } = Typography;

export const ClearTopicStateComponent: FC<{
  taskState: TaskState<TaskStateTypes>;
}> = ({ taskState }) => {
  const changeOffsetState = taskState as TaskState<ClearTopicState>;
  const { id } = changeOffsetState.state;

  return (
    <>
      <Card title="Очистка топика">
        <Space>
          <Paragraph>Топик: </Paragraph>
          <Paragraph>
            <Link href={`/topics/${id}`}>{id}</Link>
          </Paragraph>
        </Space>
      </Card>
      <br />
    </>
  );
};

export default memo(ClearTopicStateComponent);
