import { fetchCoreApi } from "utils/fetch";
import {
  KafkaClusterLive,
  KafkaClusterLiveListResponse,
  KafkaClusterLiveRequestParams,
} from "./types";

const baseRoute = "/infrastructure/internal/kafka-cluster";

export const getKafkaClustersLive = async (
  params: KafkaClusterLiveRequestParams
): Promise<KafkaClusterLiveListResponse> => {
  return await fetchCoreApi<KafkaClusterLiveListResponse>({
    route: `${baseRoute}/live`,
    query: params,
  });
};

export const getKafkaClusterLive = async (
  id: string
): Promise<KafkaClusterLive> => {
  return await fetchCoreApi<KafkaClusterLive>({
    route: `${baseRoute}/${id}/live`,
  });
};
