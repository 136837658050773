import {
  EmployeesRequestParams,
  EmployeesListResponse,
  Employee,
} from "./types";
import { fetchCoreApi } from "utils/fetch";

export const getEmployees = async (
  params: EmployeesRequestParams
): Promise<EmployeesListResponse> => {
  return await fetchCoreApi<EmployeesListResponse>({
    route: `/squad/employee`,
    query: params,
  });
};

export const getMyEmployeeInfo = async (): Promise<Employee> => {
  return await fetchCoreApi<Employee>({
    route: "/squad/employee/me",
  });
};
