import { createStore, createEvent, sample } from "effector";
import { $taskList, TaskListShouldUpdate } from "./backgoundTasks.model";
import { getTaskListFx, getTaskIdListFx } from "./backgroundTasks.datalayer";
import {
  TaskState,
  TaskStateTypes,
  TaskList,
  TaskExecutionStatus,
} from "../types/backgroundTasks.types";

// Сторы для метоинфы тасок
export const $taskStateList = createStore<TaskState<TaskStateTypes>[]>([]);
export const $taskInProcessList = createStore<TaskList[]>([]);
export const $taskCompletedList = createStore<TaskList[]>([]);

export const TaskInProcessListUpdate = createEvent<TaskList[]>();
export const TaskCompletedListUpdate = createEvent<TaskList[]>();

// при неибходимости обновить стор тасок, если айдишников нет, прописываме в стор пустой список
sample({
  source: $taskStateList,
  target: $taskList,
  fn: () => {
    return [];
  },
  filter: (source) => {
    return source.length === 0;
  },
});

// при неибходимости обновить стор тасок, если айдишники есть, ходим с ними за тасками
sample({
  clock: TaskListShouldUpdate,
  source: $taskStateList,
  target: getTaskListFx,
  filter: (source) => {
    return source.length > 0;
  },
});

// при доствании айдишников из ЛС, пишем их в стор айдишников
sample({
  clock: getTaskIdListFx.doneData,
  target: $taskStateList,
});

// обновление стора айдишников вызывает перезапрос тасок с сервака.
sample({ source: $taskStateList, target: TaskListShouldUpdate });

// собираем "выполняемые" таски
sample({
  source: $taskList,
  target: TaskInProcessListUpdate,
  fn: (list) =>
    list.filter(
      (task) =>
        task.executionStatus === TaskExecutionStatus.NOOP ||
        task.executionStatus === TaskExecutionStatus.UNKNOWN
    ),
});

// собираем выполненные таски
sample({
  source: $taskInProcessList,
  clock: TaskInProcessListUpdate,
  target: $taskCompletedList,
  fn: (data, event) => {
    if (event.length === 0) {
      return data;
    }
    if (data.length !== event.length) {
      return data.filter(
        (task) => !event.some((newTask) => newTask.name === task.name)
      );
    }
    return [];
  },
});

sample({
  clock: TaskInProcessListUpdate,
  target: $taskInProcessList,
});
