import { createEffect } from "effector";
import { ApiError } from "utils/fetch";
import {
  ConsumerGroup,
  ConsumerGroupRequestParams,
  ConsumerGroupListResponse,
  ClusterConsumerGroupListRequestParams,
  ChangeOffsetsRequestBody,
} from "../types/types";
import {
  getClusterConsumerGroups,
  getConsumerGroup,
  setOffset,
} from "../api/api";
import { getKafkaClusterLive } from "dhEntities/kafkaCluster/model/api";
import { KafkaClusterLive } from "dhEntities/kafkaCluster/model/types";
import { ChangeOffsetState } from "widgets/BackgroundTaskList";

export const getClusterConsumerGroupsFx = createEffect<
  ClusterConsumerGroupListRequestParams,
  ConsumerGroupListResponse,
  ApiError
>({
  handler: async (params: ClusterConsumerGroupListRequestParams) => {
    return await getClusterConsumerGroups(params);
  },
  sid: "getClusterConsumerGroupsFx",
});

export const getConsumerGroupFx = createEffect<
  ConsumerGroupRequestParams,
  ConsumerGroup,
  ApiError
>({
  handler: async (params: ConsumerGroupRequestParams) => {
    return await getConsumerGroup(params);
  },
  sid: "getConsumerGroupFx",
});

export const setOffsetFx = createEffect<ChangeOffsetState, string, ApiError>({
  handler: async (params: ChangeOffsetsRequestBody) => {
    return await setOffset(params);
  },
  sid: "setOffsetFx",
});

export const getKafkaClusterLiveFx = createEffect<
  string,
  KafkaClusterLive,
  ApiError
>({
  handler: async (params: string) => {
    return await getKafkaClusterLive(params);
  },
  sid: "getKafkaClusterLiveFx",
});
