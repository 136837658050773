import { clearTopicFx } from "dhEntities/ClearTopic/model/ClearTopic.model";
import { sample } from "effector";
import { TaskTypes } from "..";
import { addTaskIdFx } from "../backgroundTasks.datalayer";

sample({
  clock: clearTopicFx.doneData,
  source: clearTopicFx,
  fn: (topicId: string, taskId: string) => {
    return {
      id: taskId,
      type: TaskTypes.CLEARTOPIC,
      state: { id: topicId },
    };
  },
  target: addTaskIdFx,
});
