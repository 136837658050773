import { Button, Drawer } from "antd";
import style from "./BackgroundTaskList.module.css";
import { FC, useEffect, useState } from "react";
import { useStore, useUnit } from "effector-react";
import {
  $directDrawerState,
  $drawerState,
  $taskStateList,
  closeDrawer,
  getTaskIdListFx,
  openDrawer,
} from "../model";
import dynamic from "next/dynamic";

const BackgroundTaskList = dynamic(() => import("./BackgroundTaskList"));

export type DrawerSize = "default" | "large";

export const BackgroundTaskListDrawer: FC = () => {
  const [drawerSize, setDraverSize] = useState<DrawerSize>("default");

  const drawerSizeHandler = (size: DrawerSize) => {
    setDraverSize(size);
  };

  const handlerCloseDrawer = () => {
    closeDrawer();
    setDraverSize("default");
  };

  useEffect(() => {
    getTaskIdListFx();
  }, []);

  const taskIdList = useStore($taskStateList);

  // тут такое дело.
  // у нас два контура управления дровером.
  // локальный и директивный(из стора)
  // Это нужно для того, чтобы открывать и закрывать дровер на период выполнения таски
  // и при этом управлять дровером локально, не зависимо от значения из стора
  const drawerState = useUnit($drawerState);
  const directDrawerState = useUnit($directDrawerState);

  useEffect(() => {
    if (drawerState) {
      setDraverSize("large");
    }
  }, [drawerState]);

  return (
    <>
      <Drawer
        title="Фоновые задачи"
        placement="bottom"
        size={drawerSize}
        onClose={() => {
          handlerCloseDrawer();
        }}
        open={drawerState || directDrawerState}
        destroyOnClose={true}
      >
        <BackgroundTaskList drawerSizeHandler={drawerSizeHandler} />
      </Drawer>

      {taskIdList.length > 0 && (
        <Button
          type="primary"
          className={style.taskListTrigger}
          onClick={() => openDrawer()}
        >
          Фоновые задачи
        </Button>
      )}
    </>
  );
};
