// слой работы с данными

import { createEffect, sample } from "effector";
import { getTask, reloadTask } from "../api";

import {
  TaskState,
  TaskStateTypes,
  TaskResponse,
} from "../types/backgroundTasks.types";
import { ApiError } from "utils/fetch";
import { errorHandlerEffect } from "common/utils/errorhandler.effect";
import {
  removeTaskId,
  getTaskIdList,
  setTaskIdInLS,
} from "../helpers/BackgroundTasks.helpers";

// эффект, который удаляет таску из локалстора
export const removeTaskIdFx = createEffect({
  sid: "removeTaskIdFx",
  handler: (id: string) => removeTaskId(id),
});

// эффект, который достает таску из локалстора
export const getTaskIdListFx = createEffect({
  sid: "getTaskIdListFx",
  handler: (): TaskState<TaskStateTypes>[] => getTaskIdList(),
});

// эффект, который пишет новую таску в лс
export const addTaskIdFx = createEffect({
  sid: "addTaskIdFx",
  handler: (task: TaskState<TaskStateTypes>) => setTaskIdInLS(task),
});

// запросатор тасклиста с сервака
export const getTaskListFx = createEffect<
  TaskState<TaskStateTypes>[],
  TaskResponse,
  ApiError
>({
  sid: "getTaskListFx",
  handler: async (params: TaskState<TaskStateTypes>[]) => {
    return await getTask(params.map((item) => item.id));
  },
});

// ретрай таски
export const retryTaskFx = createEffect<string, void, ApiError>({
  handler: async (id: string) => await reloadTask(id),
});

// ловим ошибки у запроса за тасками
sample({ clock: getTaskListFx.failData, target: errorHandlerEffect });

// ловим ошибки у запроса за тасками
sample({ clock: retryTaskFx.failData, target: errorHandlerEffect });
