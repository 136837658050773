import { FC, ReactNode, useState } from "react";
import { Button, ConfigProvider, Layout, Drawer, FloatButton } from "antd";
import { SidebarMenu } from "common/SidebarMenu/SidebarMenu";
import { UpOutlined } from "@ant-design/icons";
import { Logo } from "common/Logo/Logo";
import styles from "./Layout.module.css";
import { UserAvatar } from "common/UserAvatar/UserAvatar";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { theme } from "utils/theme";
import { BackgroundTaskListDrawer } from "widgets/BackgroundTaskList/ui";

const { Header, Content, Sider } = Layout;

type LayoutProps = {
  children: ReactNode;
};

export const BaseLayout: FC<LayoutProps> = ({ children }) => {
  const [isSidebarCollapsed, setSidebarCollapse] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

  const handleCollapse = () => {
    setSidebarCollapse((v) => !v);
  };

  const handleOpenMobileMenu = () => {
    setMobileMenuVisibility(true);
  };

  const handleCloseMenu = () => {
    setMobileMenuVisibility(false);
  };
  const handleUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <ConfigProvider theme={theme}>
      <Layout className={styles.layout}>
        <Sider
          width={260}
          collapsed={isSidebarCollapsed}
          className={styles.sidebar}
        >
          <Logo size={isSidebarCollapsed ? "small" : "medium"} />
          <SidebarMenu />
        </Sider>
        <Content>
          <Header
            className={styles.header}
            style={{ position: "sticky", top: 0, zIndex: 10, width: "100%" }}
          >
            {
              <Button
                type="text"
                size="large"
                className={styles.mobileMenuButton}
                onClick={handleOpenMobileMenu}
                icon={
                  isMobileMenuVisible ? <CloseOutlined /> : <MenuOutlined />
                }
              />
            }
            <Logo size="medium" theme="light" className={styles.mobileLogo} />
            {
              <Button
                type="text"
                size="large"
                className={styles.sidebarTrigger}
                onClick={handleCollapse}
                icon={
                  isSidebarCollapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )
                }
              />
            }
            <UserAvatar />
          </Header>
          <main className={styles.content}>{children}</main>
        </Content>
        <FloatButton onClick={handleUp} icon={<UpOutlined />} />
      </Layout>
      <Drawer
        width={260}
        closable={false}
        placement="left"
        open={isMobileMenuVisible}
        onClose={handleCloseMenu}
        bodyStyle={{ background: "#1b1b1b", padding: 0 }}
      >
        <SidebarMenu onClick={handleCloseMenu} />
      </Drawer>
      <BackgroundTaskListDrawer />
    </ConfigProvider>
  );
};
