import { notification } from "antd";
import { createEffect } from "effector";
import { ApiError } from "utils/fetch";

export const errorHandlerEffect = createEffect<ApiError, void>({
  handler: (error: ApiError): void => {
    notification.error({
      message: "Ошибка " + error.code,
      description: error.message,
    });
  },
});
