// Стор управления дровером фоновых задач

import { createStore, createApi, sample } from "effector";
import { $taskList } from "./backgoundTasks.model";
import { addTaskIdFx, getTaskIdListFx } from "./backgroundTasks.datalayer";
import {
  TaskBatchStatus,
  TaskExecutionStatus,
} from "../types/backgroundTasks.types";

// локальный стейт дровера
export const $drawerState = createStore(false);

export const { openDrawer, closeDrawer } = createApi($drawerState, {
  openDrawer: () => true,
  closeDrawer: () => false,
});

// стейт дровера для паралельного управления из стора
export const $directDrawerState = createStore(false);

export const { directOpenDrawer, directCloseDrawer } = createApi(
  $directDrawerState,
  {
    directOpenDrawer: () => true,
    directCloseDrawer: () => false,
  }
);

//автоматическое закрытие дровера, когда закончились выполняемые таски
sample({
  source: $taskList,
  filter: (taskList) =>
    !taskList.some(
      (task) =>
        task.executionStatus === TaskExecutionStatus.NOOP ||
        task.executionStatus === TaskExecutionStatus.UNKNOWN
    ),
  target: directCloseDrawer,
});

// добавление новой таски в стор открывает дровер
sample({ clock: [addTaskIdFx], target: [directOpenDrawer] });

// хак, чтобы можно было руками захлопнуть выехавшее поле с текущим процессом
sample({ clock: closeDrawer, target: directCloseDrawer });

sample({
  clock: [openDrawer],
  target: [getTaskIdListFx],
});
