import "antd/dist/reset.css";
import "styles/globals.css";
import Head from "next/head";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { BaseLayout } from "common/Layout/Layout";
import { Auth } from "common/Auth/Auth";

const refetchInterval = 60;

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="user-scalable=no, width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, target-densitydpi=device-dpi, shrink-to-fit=no"
        />
      </Head>
      <SessionProvider
        session={session}
        refetchInterval={refetchInterval}
        refetchOnWindowFocus={true}
      >
        <Auth>
          <BaseLayout>
            <Component {...pageProps} />
          </BaseLayout>
        </Auth>
      </SessionProvider>
    </>
  );
}
