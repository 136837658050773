import { createEffect, sample } from "effector";
import Router from "next/router";
import {
  TaskState,
  IntegrationObjectTaskState,
  TaskList,
  TaskTypes,
  TaskStateTypes,
} from "../../types/backgroundTasks.types";
import {
  $taskStateList,
  $taskCompletedList,
} from "../backgroundTasks.success.model";
import { activateIntegrationObjectFx } from "dhEntities/integrationObject/model/events";
import { IntegartionObjectIds } from "dhEntities/integrationObject/model/types";
import { addTaskIdFx } from "../backgroundTasks.datalayer";

// Подписка на событие активации интеграционного объекта

sample({
  clock: activateIntegrationObjectFx.doneData,
  source: activateIntegrationObjectFx,
  fn: (ids: IntegartionObjectIds, data: string) => {
    return {
      id: data,
      type: TaskTypes.INTEGRATION_OBJECT_VERSION,
      state: {
        integrationObjectid: ids.integrationObjectId,
        releaseId: ids.releaseId,
        versionId: ids.versionId,
      },
    };
  },
  target: addTaskIdFx,
});

// эффект успешной таски активации ИО
export const taskIntegrationObjectCompletedFx = createEffect({
  sid: "taskIntegrationObjectCompletedFx",
  handler: (tasks: (TaskState<IntegrationObjectTaskState> | undefined)[]) => {
    tasks
      .filter(
        (task): task is TaskState<IntegrationObjectTaskState> =>
          task !== undefined
      )
      .forEach((task: TaskState<IntegrationObjectTaskState>) => {
        const ioRoute =
          "/integration-objects/" + task.state.integrationObjectid;
        if (ioRoute === Router.asPath) {
          Router.reload();
        } else {
          Router.push(ioRoute);
        }
      });
  },
});

// обработчик успешной таски активации ИО
sample({
  source: $taskStateList,
  clock: $taskCompletedList,
  fn: (taskIdlist, list: TaskList[]) => {
    const tasks = list.filter(
      (item) => item.entityType === TaskTypes.INTEGRATION_OBJECT_VERSION
    );
    return tasks.map(
      (task): TaskState<IntegrationObjectTaskState> | undefined =>
        taskIdlist.find(
          (
            info: TaskState<TaskStateTypes>
          ): info is TaskState<IntegrationObjectTaskState> =>
            info.id === task.name
        )
    );
  },
  target: taskIntegrationObjectCompletedFx,
});
