import {
  Scenario,
  ScenariosListRequest,
  ScenarioInfoRequest,
  ScenarioInfo,
  HowToResponse,
  ScenarioInfoListResponse,
  ScenariosListResponse,
} from "./types";
import { fetchCoreApi } from "utils/fetch";

export const createScenario = async (
  scenario: Omit<Scenario, "id">
): Promise<string> => {
  return await fetchCoreApi<string>({
    method: "POST",
    body: scenario,
    route: `/scenario/`,
    versionApi: 2,
  });
};

export const deleteScenario = async (id: string): Promise<string> => {
  return await fetchCoreApi<string>({
    method: "DELETE",
    route: `/scenario/${id}`,
    versionApi: 1,
  });
};

export const getScenario = async (id: string): Promise<Scenario> => {
  return await fetchCoreApi<Scenario>({
    route: "/scenario/" + id,
  });
};

export const getScenarios = async (
  params: ScenariosListRequest
): Promise<ScenariosListResponse> => {
  return await fetchCoreApi<ScenariosListResponse>({
    route: "/scenario/",
    query: params,
  });
};

export const getScenariosInfo = async (
  params: ScenarioInfoRequest
): Promise<ScenarioInfoListResponse> => {
  return await fetchCoreApi<ScenarioInfoListResponse>({
    route: "/scenario-info/",
    query: params,
  });
};
export const getScenarioInfo = async (id: string): Promise<ScenarioInfo> => {
  return await fetchCoreApi<ScenarioInfo>({
    route: `/scenario-info/${id}`,
  });
};

export const getHowTo = async (scenarioId: string): Promise<HowToResponse> => {
  const data = await fetchCoreApi<HowToResponse>({
    route: `/scenario/${scenarioId}/howto`,
  });
  return data;
};
